class Nav {
    init() {
        const navItems = document.querySelectorAll('.nav__item')
        const navCloseButton = document.querySelector('.header__burger.close')

        navItems.forEach(item => {
            let target = item.getAttribute('href')

            item.addEventListener('click', (e) => {
                if (target.indexOf('#') !== -1) {
                    e.preventDefault()

                    let event = new Event('click')
                    navCloseButton.dispatchEvent(event)

                    setTimeout(() => {
                        document.querySelector(target).scrollIntoView({
                            behavior: 'smooth'
                        })
                    }, 400)
                }
            })
        })
    }
}

export default Nav