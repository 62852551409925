import ViewportIn from '../functions/viewport-in'

class Hero {
    init() {
        this.show()

        let _this = this
        window.addEventListener('scroll', function() {
            _this.show()
        })
    }

    show() {
        const viewportIn = new ViewportIn()
        const hero = document.querySelector('.hero')

        let timeout = 1000,
            preloaderState = localStorage.getItem('preloaderState')

        if (preloaderState === 'visible') {
            timeout = 2500
        }

        if (viewportIn.check(hero)) {
            setTimeout(() => {
                if (!hero.classList.contains('visible')) {
                    hero.classList.add('visible')
                    console.log(hero)
                }
            }, timeout)
        }
    }
}

export default Hero