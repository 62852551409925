class Preloader {
    init() {
        const preloader = document.querySelector('.preloader')
        const html = document.querySelector('html')

        localStorage.setItem('preloaderState', 'visible')

        html.style.overflow = 'hidden'
        html.style.paddingRight = '17px'

        setTimeout(() => {
            localStorage.setItem('preloaderState', 'hidden')

            preloader.classList.add('hidden')
            html.style.overflow = 'visible'
            html.style.paddingRight = '0'
        }, 2000)
    }
}

export default Preloader