import ViewportIn from '../functions/viewport-in'

class FadeIn {
    init() {
        this.show()

        let _this = this
        window.addEventListener('scroll', function() {
            _this.show()
        })
    }

    show() {
        const viewportIn = new ViewportIn()
        const fadeInItems = document.querySelectorAll('.fade-in')

        let timeout = 0,
            preloaderState = localStorage.getItem('preloaderState')

        if (preloaderState === 'visible') {
            timeout = 2500
        }

        fadeInItems.forEach(item => {
            if (viewportIn.check(item)) {
                setTimeout(() => {
                    if (!item.classList.contains('visible')) {
                        item.classList.add('visible')
                    }
                }, timeout)
            }
        })
    }
}

export default FadeIn