// import Bootstrap
import 'bootstrap';

// import template styles
import './scss/main.scss'

import Header from './js/components/header'
const header = new Header()
header.init()

import Preloader from './js/components/preloader'
const preloader = new Preloader()
preloader.init()

import Nav from './js/components/nav'
const nav = new Nav()
nav.init()

import Switcher from './js/components/switcher'
const switcher = new Switcher()
switcher.init()

import Hero from './js/components/hero'
const hero = new Hero()
hero.init()

import FadeIn from './js/components/fade-in'
const fadeIn = new FadeIn()
fadeIn.init()

import Scroll from "./js/functions/scroll";
const scroll = new Scroll()
scroll.init()