class Header {
    init() {
        const header = document.querySelector('header')
        let lastScrollTop = 0

        window.addEventListener('scroll', (e) => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop
            if (scrollTop > lastScrollTop){
                header.classList.add('up')
                header.classList.add('fixed')
            } else {
                header.classList.remove('up')
                if (scrollTop === 0) {
                    header.classList.remove('fixed')
                }
            }
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
        })
    }
}

export default Header