class Scroll {
    init() {
        const links = document.querySelectorAll('a')
        links.forEach(item => {
            let target = item.getAttribute('href')

            if (target.indexOf('#') !== -1) {
                item.addEventListener('click', (e) => {
                    e.preventDefault()

                    document.querySelector(target).scrollIntoView({
                        behavior: 'smooth'
                    })
                })
            }

        })
    }
}

export default Scroll