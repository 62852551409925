class Switcher {
    init() {
        // check for saved 'darkMode' in localStorage
        let darkMode = localStorage.getItem('darkMode')

        // If the user already visited and enabled darkMode
        // start things off with it on
        if (darkMode === 'enabled') {
            this.enableDarkMode()
        }

        const darkModeToggle = document.querySelector('#dark-mode-toggle')

        darkModeToggle.addEventListener('click', () => {
            // get darkMode setting
            darkMode = localStorage.getItem('darkMode');

            if (darkMode !== 'enabled') {
                this.enableDarkMode()
            } else {
                this.disableDarkMode()
            }
        })
    }

    enableDarkMode() {
        // 1. Add the class to the body
        document.body.classList.add('darkmode')

        // 2. Update darkMode in localStorage
        localStorage.setItem('darkMode', 'enabled')
    }

    disableDarkMode() {
        // 1. Remove the class from the body
        document.body.classList.remove('darkmode')

        // 2. Update darkMode in localStorage
        localStorage.setItem('darkMode', null)
    }


}

export default Switcher